import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/glossary-main.module.css";

const GlossaryMain = (props) => {

  const {
    content
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className={cn("entry-content", styles.content)}>
          {content && (<PortableText blocks={content} />)}
        </div>
      </Container>
    </div>
  );
}

export default GlossaryMain;
