import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import { Helmet } from 'react-helmet';

import PageHeader from "../components/glossary-page-header";
import GlossaryMain from "../components/glossary-main";
import CallToAction from "../components/call-to-action";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query GlossaryTemplateQuery($id: String!) {
    glossary: sanityGlossary(id: { eq: $id }) {
      id
      title
      _rawContent
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    glossaryLanding: sanityGlossaryLanding {
      themePurple
      callToAction {
        title
        _rawDescription
        button {
          blank
          secondaryButton
          text
          url
        }
      }
    }
  }
`;

const GlossaryTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const glossaryLanding = (data || {}).glossaryLanding;
  const glossary = (data || {}).glossary;

  const title = glossary.seo?.title || glossary.title;
  const description = glossary.seo?.description;
  const image = glossary.seo?.thumbnail;
  const autoSiteTitle = !glossary.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={glossaryLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {glossary.title && (<PageHeader title={glossary.title} />)}
      {glossary._rawContent && (<GlossaryMain content={glossary._rawContent} />)}
      {glossaryLanding.callToAction && (<CallToAction {...glossaryLanding.callToAction} />)}
    </Layout>
  );
};

export default GlossaryTemplate;
