import React from "react";
import Container from "./container";
import { Link } from "gatsby";
import Icon from "./icon";

import * as styles from "../styles/components/glossary-page-header.module.css";

const PageHeader = (props) => {

  const {
    title
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <Link className={styles.back} to="/glossary"><Icon symbol="arrow-left" />Back</Link>
        {title && (<h1 className={styles.title}>{title}</h1>)}
      </Container>
    </div>
  );
}

export default PageHeader;
